import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Boice Bros. Home"
        description = "Boice Bros. Ice Cream is a Hudson Valley family owned and operated business that makes and sells farm fresh ice cream. We offer a wide variety of creamy, flavorful ice cream, including hard and soft serve, ice cream cakes and pies."
      />
      <div className="page home">
        <div className="inner">

        <div className="container">

          <div className="topcontent">
            

            <p>Boice Bros. Ice Cream is a Hudson Valley family owned and operated business that makes and sells farm fresh ice cream.
              We offer a wide variety of creamy, flavorful ice cream, including hard and soft serve, ice cream cakes and pies.
              Boice Bros. Ice Cream is your one-stop-shop for the best tasting ice cream all year long.</p>

            <h2 className="color_pink">Location, Days & Hours</h2>
            <p>62 O'Neil Street, Kingston, NY 12401<br/>
              Open Monday – Saturday 8am-8pm<br/>
              Sunday 9am - 8pm</p>

            <h2 className="color_yellow">Ice Cream Cakes and Pies,<br/>
              Ice Cream Sandwiches<br/>
              and Special Orders </h2>

            <p>We sell stock ice cream cakes, ice cream pies (cow pies), 
            ice cream sandwiches and create specialty cakes made to
            order.</p>

            <p><Link className="color_yellow" to='/ice-cream-cakes-pies'>Get details and order information ></Link></p>
          </div>

          <div className="middle_content">
            <h2 className="color_fuctia">Ice Cream - Flavors & Toppings</h2>
            <p>We offer:  hard ice cream, soft serve, sundaes, banana splits,
               ice cream sandwiches, cones, milk shakes, parfaits, floats, 
              hurricanes, and sodas.  We also offer ice cream cakes, and hard 
              ice cream pints and quarts for purchase.</p>
          </div>

          <div className="other_middle_content">
            <div className="inner">
  
              <div className="left color_pink">
                <h2>HARD SERVE ICE CREAM</h2>
                <p>Small, medium, large
                  cup, cone, sundae Chocolate, vanilla, strawberry, pistachio-cardamom, choc-choc chip, chocolate peanut butter, black raspberry, coffee, banana chip, mint chip, Syrian date & walnut.</p>
              </div>

              <div className="center">
                <h2 className="color_yellow">SOFT SERVE ICE CREAM</h2>
                <p>Small, medium, large
                cup, cone, sundae.</p>

                <p>Chocolate, vanilla, twist,
                coffee, orange cream sickle, mint chip, strawberry, hazelnut, pistachio, lemoncello, mango
                Sugar-free
                and Seasonal flavors. </p>
              </div>

              <div className="right">
                <h2  className="color_purple">TOPPINGS</h2>
                <p>Hot fudge, chocolate syrup, strawberry, wet walnuts,
                 marshmallow, pineapple, peanut butter, chopped
                 nuts, sprinkles,
                 Butterfinger, Reese’s
                 Pieces, Nestle Crunch, M&Ms, Heath Bar, Oreos.</p>
              </div>

            </div>

          </div>

          <div className="new_text">
            <h2 className="color_purple"><Link className="color_purple" to="/worlds-longest-ice-cream-sundae">World's Longest Ice Cream Sundae</Link></h2>
          </div>


          </div>
        </div>
      </div>
    </Layout>
  )



}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
